<!-- 新增文档类型 -->
<template>
  <!-- 弹窗 -->
  <Dialog :data="dialog" @submit="submit">
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="文件类型:" prop="postCode">
        <el-select v-model="formData.fileTypeId" clearable placeholder="请选择">
          <el-option v-for="item in fileTypeData" :key="item.id" :label="item.type_name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="文件:">
        <Upload :file-list.sync="formData.fileList" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
import { getFileTypelist } from '@/api/file'
export default {
    name: 'TypeView',
    props: {
        formData: { type: Object, default: () => {} }
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, trigger: 'blur', message: '请输入名称！' }
                ]
            },
            fileTypeData: [],
            dialog: {
                sign: '',
                title: '',
                switch: false,
                open: () => {}
            }
        }
    },
    created() {
        this.getTypeList()
    },
    methods: {
        getTypeList() {
            getFileTypelist().then(res => {
                if (res.code === 0) {
                    this.fileTypeData = res.list
                }
            })
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$emit('submit', { formData: this.formData, sign: this.dialog.sign })
                } else {
                    return false
                }
            })
        },
        resetFields() {
            this.formData.fileList = []
            this.formData.fileTypeId = ''

            this.$refs.form.resetFields()
        },
        openDialog(config = { title: '', sign: '', switch: '' }) {
            this.dialog.title = config.title
            this.dialog.sign = config.sign
            this.dialog.switch = config.switch
        }
    }
}
</script>

