<!-- 文件列表 -->
<template>
  <Container>
    <template #header>
      <div class="box-header box-search" style="margin-bottom:20px">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="文件类型:" prop="postCode">
            <el-select v-model="fileTypeId" clearable placeholder="请选择">
              <el-option v-for="item in fileTypeData" :key="item.id" :label="item.type_name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="search" @click="getList()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-button-group>
        <el-button type="primary" @click="onAdd">新增</el-button>
      </el-button-group>
    </template>
    <Table :head-config="listConfig.headConfig" :load-data="listConfig.dataList">
      <template v-slot:handle="scope">
        <el-button type="primary" size="mini" @click="edit(scope.data)">编辑</el-button>
        <el-button type="danger" size="mini" @click="del(scope.data)">删除</el-button>
      </template>
    </Table>

    <FileView ref="fileView" :form-data.sync="form" @submit="submit" />
  </Container>
</template>

<script>
import FileView from './components/FileView'
import { getFileTypelist, getFileList, updateFile, getFileDetail, delFile } from '@/api/file'
export default {
    components: { FileView },
    data() {
        return {
            listConfig: {
                headConfig: [ // 表头数据
                    { label: '文件名', field: 'file_name' },
                    { label: '所属分类', field: 'type_name' },
                    { label: '格式', field: 'file_suffix' },
                    { label: '文件大小', field: 'file_size' },
                    { label: '下载次数', field: 'down_nums' },
                    { label: '上传时间', field: 'upload_time' },
                    { label: '操作', columnType: 'slot', slotName: 'handle', width: 180 }
                ],
                dataList: [], // table数据
                pageData: { total: 0, pageIndex: 1, pageSize: 10 } // 分页数据
            },
            form: {
                type_name: '',
                fileList: [],
                fileTypeId: '',
                id: ''
            },
            fileTypeData: [],
            fileTypeId: ''
        }
    },
    computed: { },
    watch: {},
    created() {
        this.getList()
        this.getTypeList()
    },
    mounted() { },
    methods: {
        getList() {
            getFileList({ id: this.fileTypeId }).then(res => {
                if (res.code === 0) {
                    this.listConfig.dataList = res.list
                }
            })
        },
        getTypeList() {
            getFileTypelist().then(res => {
                if (res.code === 0) {
                    this.fileTypeData = res.list
                }
            })
        },
        onAdd() {
            this.$refs.fileView.openDialog({ sign: 'add', title: '新增', switch: true })
        },
        edit(e) {
            getFileDetail({ id: e.row.id }).then(res => {
                if (res.code === 0) {
                    this.form.fileList = []
                    this.form.id = e.row.id
                    this.form.fileList.push(res.list.fileList)
                    this.form.fileTypeId = res.list.fileTypeId
                    this.getTypeList()
                    this.$refs.fileView.openDialog({ sign: 'edit', title: '编辑', switch: true })
                }
            })
        },
        del(v) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delFile({ id: v.row.id, status: 3 }).then(res => {
                    if (res.code === 0) {
                        this.getList()
                    }
                })
            })
        },
        submit(val) {
            updateFile(val.formData).then(res => {
                if (res.code === 0) {
                    this.$refs.fileView.resetFields()
                    this.$refs.fileView.dialog.switch = false
                    this.getList()
                    this.getTypeList()
                }
            })
        }
    }
}
</script>

